<template>
    <div class="calendar-block content-block" :class="rootClasses">
        <div class="container">
            <div class="row">
                <div class="description-block">
                    <div class="description-title">
                        <p>{{ data.title }}</p>
                    </div>
                    <div class="description-text" v-html="data.text"></div>
                </div>
                <div
                    class="col-12 col-md-5 caledar-list"
                    data-scroll
                    data-scroll-opacity
                >
                    <EventCard
                        :day="getFormattedDate(date, 'day')"
                        :month="getFormattedDate(date, 'month')"
                        :weekday="getFormattedDate(date, 'weekday')"
                        :bgColor="'#00A983'"
                    >
                        <template v-slot:content>
                            <InfoLinks
                                class="info-links"
                                :textColor="'#FFFFFF'"
                                :links="data.list"
                            />
                        </template>
                    </EventCard>
                </div>
                <div
                    class="col-12 col-md-7 calendar-editor"
                    data-scroll
                    data-scroll-opacity
                >
                    <DatePick
                        v-model="date"
                        :nextMonthCaption="nextArrow"
                        :prevMonthCaption="prevArrow"
                        :hasInputElement="false"
                        :weekdays="weekDaysCalendar"
                        :parseDate="parseDate"
                        :selectableYearRange="selectableYearRange"
                    >
                    </DatePick>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DatePick from 'vue-date-pick'
import fecha from 'fecha'
import 'vue-date-pick/dist/vueDatePick.css'
import EventCard from '../components/Helpers/EventCard.vue'
import InfoLinks from '../components/Lists/InfoLinks.vue'

export default {
    name: 'Calendar',
    components: {
        DatePick,
        EventCard,
        InfoLinks
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        dates() {
            let dates = Object.values(this.data.list)
            let dateObjectContainer = {}
            dates.forEach(function (dateObject) {
                let date = dateObject.date.value

                if (dateObjectContainer.hasOwnProperty(date)) {
                    dateObjectContainer[date].push(
                        JSON.parse(JSON.stringify(dateObject))
                    )
                } else {
                    dateObjectContainer[date] = [
                        JSON.parse(JSON.stringify(dateObject))
                    ]
                }
            })
            return dateObjectContainer
        },
        rootClasses() {
            let result = {
                ['cs-calendar']: true,
                ['theme-block']: true
            }

            if (this.data?.hasBackground)
                result = {
                    ...result,
                    ...{
                        ['has-background']: true,
                        ['theme-bg-colored']: true
                    }
                }

            return result
        }
    },
    methods: {
        getCurrentDate() {
            const today = new Date()

            return today.toISOString().split('T')[0]
        },
        parseDate(dateString, format) {
            if (this.dates.hasOwnProperty(dateString)) {
                const events = this.dates[dateString]

                let newLinks = []

                events.forEach(function (event) {
                    newLinks.push({
                        url: event.link?.value ?? '',
                        title: event.title?.value ?? '',
                        text: event.text?.value ?? ''
                    })
                })

                this.links = newLinks
            }
            return fecha.parse(dateString, format)
        },
        getFormattedDate(date, format) {
            // Accepts date in format 2021-12-31, returns numeric day with 0 at begining (if day < 10), month (name), weekday (name)
            let result = ''

            if (typeof date !== 'undefined') {
                const dateObj = new Date(date)

                switch (format) {
                    case 'day':
                        result = dateObj.getDate()

                        if (parseInt(result) < 10) {
                            result = '0' + result
                        }
                        break
                    case 'month':
                        result = this.monthNames[dateObj.getMonth()]
                        break
                    case 'weekday':
                        result = this.weekDays[dateObj.getDay() - 1]
                        break
                    default:
                        break
                }
            }

            return String(result)
        }
    },
    mounted() {
        this.date = this.getCurrentDate()
    },
    data() {
        return {
            listSelectedDate: {},
            selectedDate: {
                day: '27',
                month: 'april',
                weekday: 'sunday'
            },
            range: {},
            date: '',
            weekDaysCalendar: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
            selectableYearRange: { from: 2021, to: 2021 },
            nextArrow: '',
            prevArrow: '',
            monthNames: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
            ],
            weekDays: [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday'
            ],
            links: []
        }
    }
}
</script>

<style lang="scss">
@import '../styles/main.scss';

::v-deep {
    .event-info__list li {
        margin: 0;
    }
}

.description-block {
    text-align: center;

    .description-title {
        @include title;
    }

    .description-text {
        @include text;

        ::v-deep {
            * {
                color: $primary_text_color;
            }

            p {
                @include text;
            }
        }
    }
}

.caledar-list {
    .event-card {
        padding: 0;
        padding-right: 30px;
        background: none;

        .event-card__header {
            .day-month {
                display: flex;
                align-items: baseline;

                .time_day {
                    margin-right: $default_margin;
                }

                .time_day,
                .time_month {
                    color: $theme_color;
                    font-family: $family;
                }
            }
            .time_week {
                @include title;
                font-family: $family;
            }
        }
    }

    .info-links {
        li {
            background: none;
            padding-left: 0px;
            margin-top: 10px !important;
        }
        .link {
            .link-title {
                @include title;
                font-size: $regular_text_size;
                color: $primary_text_color !important;
                font-family: $family !important;
                svg {
                    fill: red;
                }
            }

            .info-text {
                @include text;
                color: $primary_text_color !important;
                font-family: $family !important;
                margin: 0 !important;
                padding-right: 25px;
            }

            .arrow-icon {
                path {
                    fill: $theme_color !important;
                }
            }
        }
    }
}

.calendar-editor {
    .vdpComponent {
        height: 100%;
    }
    .vdpOuterWrap {
        height: 100%;
    }
    .vdpInnerWrap {
        height: 100%;
        border: none;
        box-shadow: none !important;
        .vdpHeader {
            background-color: transparent !important;
            .vdpArrowPrev::after {
                border-right-color: $secondary_text_color;
            }
            .vdpArrowNext::after {
                border-left-color: $secondary_text_color;
            }
        }

        .vdpPeriodControl {
            button {
                @include title;
            }
        }
        .vdpTable {
            height: 100%;
            .vdpHeadCell {
                @include title;
                font-size: $regular_text_size;

                .vdpHeadCellContent {
                    color: $primary_text_color;
                }
            }

            .vdpRow {
                .vdpCell {
                    @include text;

                    .vdpCellContent {
                        color: $primary_text_color;

                        .today {
                            background: $theme_color;
                        }
                    }
                }

                .vdpCell.selected .vdpCellContent {
                    color: $text_color_over_theme_color;
                    background: $theme_color;
                }

                .vdpCell:hover {
                    .vdpCellContent {
                        background: $theme_color;
                        color: $text_color_over_theme_color;
                    }
                }
            }
        }
    }
}
</style>
