<template>
	<div :style="cssVars" class="event-card">
		<div class="event-card__header">
            <div class="day-month">
                <div class="header-time time_day">{{ day }}</div>
                <div class="header-time time_month">{{ month }}</div>
            </div>
			<div class="header-time time_week">{{ weekday }}</div>
		</div>
		<slot name="content"></slot>
	</div>
</template>

<script>
export default {
    name: 'EventCard',
    props: {
        day: {
            type: String,
            default: '00'
        },
		month: {
            type: String,
            default: 'month'
        },
		weekday: {
            type: String,
            default: 'weekday'
        },
        bgColor: {
            type: String,
            default: '#00A983'
        },
        textColor: {
            type: String,
            default: '#FFFFFF'
        }
    },
    computed: {
        cssVars() {
            return {
                '--event-card-bg': this.bgColor,
                '--event-text-color': this.textColor
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../styles/_mixins.scss';

.event {
	&-card {
        background: var(--event-card-bg);
        padding: 30px;

        @media (min-width: 768px) {
            padding: 45px 35px;
        }

        @media (min-width: 1400px) {
            padding: 75px 50px;
        }

        &__header {
            display: flex;
            flex-wrap: wrap;

            .header-time {
                &.time_day {
                    font-size: 120px;
                    font-family: $font_family;
                    font-style: normal;
                    font-weight: bold;
                    color: var(--event-text-color);
                    line-height: 120%;
					width: 100%;

                    @media screen and (max-width: 1400px) {
                        font-size: 68px;
                    }
                    @media screen and (max-width: 1200px) {
                        font-size: 58px;
                    }
                    @media screen and (max-width: 991px) {
                        font-size: 48px;
                    }
                }
                &.time_month {
                    font-size: 48px;
                    font-family: $font_family;
                    font-style: normal;
                    font-weight: bold;
                    color: var(--event-text-color);
                    line-height: 120%;
                    text-transform: lowercase;
					width: 100%;

                    @media screen and (max-width: 1400px) {
                        font-size: 38px;
                    }
                    @media screen and (max-width: 1200px) {
                        font-size: 28px;
                    }
                    @media screen and (max-width: 991px) {
                        font-size: 18px;
                    }
                }
                &.time_week {
                    flex: 0 0 100%;
                    max-width: 100%;
                    width: 100%;
                    font-size: 36px;
                    font-family: $font_family;
                    font-style: normal;
                    font-weight: 500;
                    color: var(--event-text-color);
                    line-height: 120%;
                    margin-top: 10px;
					text-transform: capitalize;

                    @media screen and (max-width: 1200px) {
                        font-size: 26px;
                    }
                    @media screen and (max-width: 991px) {
                        font-size: 18px;
                    }
                }
            }
        }

		::v-deep .event-info__list {
            li {
                a {
                    h4 {
						color: var(--event-text-color);

                        svg {                            
                            path {
                                fill: var(--event-text-color);
                            }
                        }
                    }
                    > div {
						color: #AFE0D5;
                    }
                }
            }
        }
    }
}
</style>
