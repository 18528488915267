<template>
	<ul :style="cssVars" class="event-info__list" v-if="links.length > 0">
		<li v-for="(link, key) in links" :key="key">
			<a :href="link.url" class="link">
				<h4 class="link-title" v-if="link.title.length > 0">
					{{ link.title }}
					<font-awesome-icon class="arrow-icon" icon="custom-arrow-right"/>
				</h4>
				<div class="info-text" v-if="link.text.length > 0" v-html="link.text"/>
			</a>
		</li>
	</ul>
</template>

<script>

import { library } from '@fortawesome/fontawesome-svg-core';
import { customArrowRight } from "../../assets/icons";

library.add( customArrowRight );

export default {
    name: 'EventCard',
    props: {
        links: {
            type: Array,
            default: []
        },
        textColor: {
            type: String,
            default: '#000000'
        },
        iconColor: {
            type: String,
            default: '#000000'
        },
    },
    computed: {
        cssVars() {
            return {
                '--info-links-color': this.textColor,
                '--icon-color': this.iconColor,
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../styles/_mixins.scss';

.event {
    &-info {
		&__list {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                margin: $line_margin_md 0 0;

                @media screen and (max-width: 991px) {
                    margin: $line_margin_sm 0 0;
                }

                a {
                    text-decoration: none;

                    &:hover {
                        text-decoration: none;
                    }

                    h4 {
                        @include comserve-small-title;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin: 0;
                        color: var(--info-links-color);

                        svg {
                            margin-left: 10px;
                            width: 20px;
                            height: 17px;
                            
                            path {
                                fill: var(--icon-color);
                            }
                        }
                    }
                    > div {
                        @include comserve-text;
                        margin: 10px 0 0;
                        color: var(--info-links-color);

                        ::v-deep *:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
	}
}
</style>
